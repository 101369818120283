import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { io } from 'socket.io-client';
import { useParams } from 'react-router-dom';
import { mergeData } from '../../helpers/mergeData';
import { receivePriceLists } from '../../controller/socketIOOperations';
import { ProductRow } from './ProductRow';
import { handleSave } from '../shared/handleSave';
import { TopOptions } from './TopOptions';

export function ProductList() {
      const { productName, productGroup } = useParams();
      const highlightProduct = productName;
      const productRefs = useRef({});
      const [data, setData] = useState([]);
      const [isSaving, setIsSaving] = useState(false);
      const [totalSearches, setTotalSearches] = useState(0);
      const [newProductName, setNewProductName] = useState('');
      const [newProductPrice, setNewProductPrice] = useState('');

      const handleAddNewProduct = async () => {
            const newProduct = {
                  ProductName: newProductName,
                  averagePrice: parseFloat(newProductPrice),
                  searchedBy: [],
                  manuallychecked: true,
                  timesSearched: 0
            };
            handleSave([newProduct], setIsSaving);
      };

      useEffect(() => {
            (async () => {
                  console.log(productGroup, 'what is this in its first layer');
                  const checkup = await receivePriceLists(productGroup);
                  console.log(checkup, productGroup, 'what are is you');
                  mergeData(checkup, setData, setTotalSearches);
            })();
            const socket = io(process.env.REACT_APP_ENDPOINT);

            socket.on('update-prices', (pricesData) => {});
            return () => socket.off('update-prices');
      }, [productGroup]);

      useEffect(() => {
            if (highlightProduct && productRefs.current[highlightProduct]) {
                  setTimeout(() => {
                        productRefs.current[highlightProduct].scrollIntoView({
                              behavior: 'smooth',
                              block: 'nearest'
                        });
                  }, 0);
            }
      }, [highlightProduct, data]);

      return (
            <div className="App">
                  <div className="App-content">
                        <TopOptions
                              data={data}
                              setIsSaving={setIsSaving}
                              newProductName={newProductName}
                              setNewProductName={setNewProductName}
                              newProductPrice={newProductPrice}
                              setNewProductPrice={setNewProductPrice}
                              handleAddNewProduct={handleAddNewProduct}
                              isSaving={isSaving}
                        />
                        <table>
                              <thead>
                                    <tr>
                                          <th>Product Name</th>
                                          <th>Average Price</th>
                                          <th>eBay Link</th>
                                          <th>Last Updated</th>
                                          <th>Search %</th>
                                          <th>Delete</th>
                                    </tr>
                              </thead>
                              <tbody>
                                    {data.map((product) => {
                                          return (
                                                <ProductRow
                                                      key={product.ProductName}
                                                      product={product}
                                                      setIsSaving={setIsSaving}
                                                      setData={setData}
                                                      highlightProduct={highlightProduct}
                                                      totalSearches={totalSearches}
                                                      productRefs={productRefs}
                                                      productGroup={productGroup}
                                                />
                                          );
                                    })}
                              </tbody>
                        </table>
                  </div>
            </div>
      );
}
