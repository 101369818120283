import React, { useState, useEffect } from 'react'
import { receivePaypalData } from './socketIO'

export function Paypal() {
  // State to hold filtered PayPal data
  const [filteredPaypalData, setFilteredPaypalData] = useState([])
   const [paypalData, setPaypalData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const paypalData = await receivePaypalData()
        setPaypalData(paypalData)
        // Filter to only include objects with transactions_notes
        const dataWithTransactionNotes = paypalData.filter(
          (item) => item.transaction_note
        )
        setFilteredPaypalData(dataWithTransactionNotes)
      } catch (error) {
        console.error('Error fetching PayPal data:', error)
      }
    }

    fetchData()
  }, []) // Empty dependency array means this effect runs once on mount

  return (<div><TransactionTable transactions={paypalData}/></div>
   
  )
}
 // <div>
    //   {filteredPaypalData.length > 0 ? (
    //     <ul>
    //       {filteredPaypalData.map((item, index) => (
    //         <li key={index}>
    //           {/* Assuming you want to display the transactions_notes */}
    //           {item.transaction_note}
    //         </li>
    //       ))}
    //     </ul>
    //   ) : (
    //     <p>No transactions notes found.</p>
    //   )}
// </div>


const TransactionTable = ({ transactions }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Start Date</th>
          <th>Value (USD)</th>
          <th>Alternate Full Name</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction, index) => (
          <tr key={index}>
            <td>{new Date(transaction.start_date).toLocaleString()}</td>
            <td>{transaction.transaction_details[0].transaction_info.transaction_amount.value}</td>
            <td>{transaction.transaction_details[0].payer_info.payer_name.alternate_full_name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
