import React, { useState, useEffect } from 'react';

export const RenderContentField = ({ item, itemIndex, contentField, contentIndex, handleInputChange, objectAddress = false }) => {
      const getFieldValue = () => (objectAddress && item[objectAddress] ? item[objectAddress][contentField.name] : item[contentField.name]) || '';

      const [inputValue, setInputValue] = useState(getFieldValue());
      const [initialValue, setInitialValue] = useState(getFieldValue());

      const handleChange = (event) => {
            setInputValue(event.target.value);
      };

      const handleBlur = (event) => {
            if (inputValue !== initialValue) {
                  const { name, value } = event.target;
                  handleInputChange({ target: { name, value } }, contentIndex, name, true, objectAddress);
                  setInitialValue(inputValue);
            }
      };

      useEffect(() => {
            const newValue = getFieldValue();
            if (newValue !== initialValue) {
                  setInputValue(newValue);
                  setInitialValue(newValue);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [getFieldValue()]);

      switch (contentField?.type) {
            case 'inputField':
                  return (
                        <div key={`${itemIndex}${contentIndex}`} className="mb-3 row align-items-center">
                              <label htmlFor={contentField.name} className="col-sm-2 col-form-label">
                                    {contentField.friendlyName}
                              </label>
                              <div className="col-sm-4 d-flex align-items-center">
                                    <input type="text" className="form-control" id={contentField.name} name={contentField.name} value={inputValue} onChange={handleChange} onBlur={handleBlur} />
                              </div>
                        </div>
                  );
            case 'dropdown':
                  return (
                        <div key={`${itemIndex}${contentIndex}`} className="mb-3 row">
                              <label htmlFor={contentField.name} className="col-sm-2 col-form-label">
                                    {contentField.friendlyName}
                              </label>
                              <div className="col-sm-4">
                                    <select
                                          className="form-select"
                                          id={contentField.name}
                                          name={contentField.name}
                                          value={objectAddress ? item[objectAddress][contentField.name] : item[contentField.name] || ''}
                                          onChange={(e) => handleInputChange(e, contentIndex, contentField.name, true, objectAddress)}
                                    >
                                          <option value="">Select Option</option>
                                          {contentField.options &&
                                                contentField.options.map((option, optionIndex) => (
                                                      <option key={optionIndex} value={option.name}>
                                                            {option.friendlyName}
                                                      </option>
                                                ))}
                                    </select>
                              </div>
                        </div>
                  );
            default:
                  return null;
      }
};
