import { receiveEbayMessages } from "./SocketIOOperations";
import { useEffect } from "react";


export function CustomerService() {


    useEffect(() => {
        (async () => {
            const ebayMessages = await receiveEbayMessages()
          
        })()




    

})
return (
    <div>
        <h1>Customer Service</h1>
    </div>
)
}