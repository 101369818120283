import { io } from 'socket.io-client';

export const receivePriceLists = async (productGroup, productName) => {
      const socket = io(process.env.REACT_APP_ENDPOINT, {
            path: '/socket.io',
            transports: ['websocket', 'polling'] // Prefer WebSocket but allow polling fallback
      });

      // Creating a promise that resolves when the socket receives the data
      const collections = await new Promise((resolve) => {
            console.log(productGroup, productName, 'what is this');
            socket.emit('prices', JSON.stringify({ productGroup: productGroup, productName: productName }), (data) => {
                  socket.disconnect(); // Ensure to disconnect after receiving data
                  resolve(data); // Resolve the promise with the received data
            });
      });

      return collections;
};

export const receiveIndividualProduct = async (productNameOrID) => {
      const socket = io(process.env.REACT_APP_ENDPOINT, {
            path: '/socket.io',
            transports: ['websocket', 'polling'] // Prefer WebSocket but allow polling fallback
      });

      socket.emit('individualProduct', JSON.stringify(productNameOrID), () => {
            socket.disconnect();
      });

      const promise = new Promise((resolve) => {
            socket.on('individualProduct', (product) => {
                  resolve(product);
                  socket.off('individualProduct');
            });
      });
      const product = await promise;
      return product;
};

export const receivePriceCollections = async () => {
      const socket = io(process.env.REACT_APP_ENDPOINT, {
            path: '/socket.io',
            transports: ['websocket', 'polling'] // Prefer WebSocket but allow polling fallback
      });
      const promise = new Promise((resolve) => {
            socket.on('priceCollections', (collections) => {
                  resolve(collections);
                  socket.off('priceCollections');
            });
      });

      const collections = await promise;
      return collections;
};

export const requestData = async (arrayOfRequests) => {
      const socket = io(process.env.REACT_APP_ENDPOINT, {
            path: '/socket.io',
            transports: ['websocket', 'polling'] // Prefer WebSocket but allow polling fallback
      });

      // Initialize a promise that will handle the asynchronous request
      const collections = await new Promise((resolve) => {
            socket.emit('getData', JSON.stringify(arrayOfRequests), (response) => {
                  socket.disconnect();
                  resolve(response); // Resolve the promise with the response data
            });
      });

      return collections; // Return the fetched collections
};
