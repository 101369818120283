export const fieldNameFunction = (ebayStrippedData, setFieldNames) => {
      setFieldNames([
            {
                  name: 'foldableMenu',
                  type: 'foldableMenu',
                  friendlyName: 'Expand',
                  content: [
                        { name: 'ebayTitle', friendlyName: 'Ebay Title', type: 'inputField' },
                        { name: 'brand', friendlyName: 'Brand', type: 'inputField' },
                        { name: 'model', friendlyName: 'Model', type: 'inputField' },
                        { name: 'ean', friendlyName: 'EAN', type: 'inputField' },
                        { name: 'color', friendlyName: 'Color (German)', type: 'inputField' },
                        { name: 'salesPrice', friendlyName: 'Sales Price', type: 'inputField' },
                        {
                              name: 'storageCapacity',
                              friendlyName: 'Storage Capacity',
                              type: 'inputField'
                        },
                        { name: 'battery', friendlyName: 'Battery %', type: 'inputField' },
                        {
                              name: 'condition',
                              friendlyName: 'Condition',
                              type: 'dropdown',
                              options: [
                                    { name: 'noScratch', friendlyName: 'no scratches' },
                                    {
                                          name: 'FrameUsed',
                                          friendlyName: 'Frame scratched, glass scratchfree'
                                    },
                                    {
                                          name: 'DisplayUsed',
                                          friendlyName: 'Glass has scratches, frame is scratchfree'
                                    },
                                    {
                                          name: 'fullScratch',
                                          friendlyName: 'Glass has scratches, frame has scratches'
                                    },
                                    {
                                          name: 'glasDamage',
                                          friendlyName: 'Glass is damaged'
                                    },
                                    {
                                          name: 'defekt',
                                          friendlyName: 'sold a defektive device'
                                    },
                                    {
                                          name: 'minorScratch',
                                          friendlyName: 'minimum user traces, not directly visible'
                                    }
                              ]
                        },
                        {
                              name: 'accessoires',
                              friendlyName: 'Accessoires',
                              type: 'dropdown',
                              options: [
                                    { name: 'none', friendlyName: 'No accessoires' },
                                    { name: 'box', friendlyName: 'Packaging' },
                                    { name: 'boxandcharger', friendlyName: 'Box and Charger' }
                              ]
                        },
                        {
                              name: 'productType',
                              friendlyName: 'Type of Product',
                              type: 'dropdown',
                              options: [
                                    { name: 'phone', friendlyName: 'Phone' },
                                    { name: 'other', friendlyName: 'Other' }
                              ]
                        },
                        {
                              name: 'customDescription',
                              friendlyName: 'Custom Description:',
                              type: 'inputField'
                        }
                        // Add other items in the foldable menu if needed
                  ]
            },

            { name: 'ProductName', friendlyName: 'Product Name', type: 'inputField' },
            { name: 'adId', friendlyName: 'Advertisement ID', type: 'inputField' },
            // { name: 'adText', friendlyName: 'Ad Text', type: 'inputField' },
            { name: 'notes', friendlyName: 'Notes', type: 'inputField' },
            // { name: 'amountPayed', friendlyName: 'Amount Payed', type: 'inputField' },
            // {
            //       name: 'paymentMethod',
            //       friendlyName: 'Method of payment',
            //       type: 'dropdown',
            //       dropDownOptions: ['Wise-Creditcard-1818-Kleinanzeigen', 'Revolut-Creditcard-442-kleinanzeigen', 'Paypal-Creditcard-0346-kleinanzeigen', 'Paypal', 'Cash']
            // },
            // {
            //       name: 'ShippingNumber',
            //       friendlyName: 'Shipping number',
            //       type: 'inputField'
            // },
            {
                  name: 'status',
                  friendlyName: 'LiveCycle',
                  type: 'dropdown',
                  dropDownOptions: [
                        { value: '1.Payed', color: '#4CAF50' },
                        { value: '2.Shippingnumber received', color: '#2196F3' },
                        { value: '3.shipped out', color: '#2196F3' },
                        { value: '4.received', color: '#4CAF50' },
                        { value: '5.tested', color: '#8BC34A' },
                        { value: '6.posted', color: '#CDDC39' },
                        { value: '7.sold (Not shipped)', color: '#FFC107' },
                        { value: '7.sold', color: '#4CAF50' },
                        { value: 'p1.draft made on ebay', color: '#FF9800' },
                        { value: 'r1.Awaiting parts or accessories', color: '#FFC107' },
                        { value: 'r2.Shipped to repair center', color: '#03A9F4' },
                        { value: 'x1.return requested', color: '#F44336' },
                        { value: 'x2.on its way back', color: '#FF5722' },
                        { value: 'x3.refunded', color: '#F44336' },
                        { value: 'p1.case opened', color: '#9C27B0' },
                        { value: 'e1.unsolved ghost issue', color: '#E91E63' }
                  ]
            },
            { name: 'lastUpdate', friendlyName: 'Last Update', type: 'lastUpdate' },
            // { name: 'profit', friendlyName: 'Estimate profit', type: 'profit' },
            {
                  name: 'EbaySale',
                  friendlyName: 'Ebay Sale connect',
                  type: 'dropdown',
                  dropDownOptions: ebayStrippedData
            },
            // { name: 'PurchaseDate', friendlyName: 'Purchase Date', type: 'datepicker' },
            { name: 'IMEI', friendlyName: 'IMEI', type: 'inputField' },
            { name: 'delete', friendlyName: 'Delete', type: 'button' },
            {
                  name: 'foldableMenu-Section2',
                  type: 'foldableMenu-Section2',
                  objectAdress: 'ShippingAddress',
                  content: [
                        {
                              name: 'Name',
                              friendlyName: 'Name',
                              type: 'inputField'
                        },
                        {
                              name: 'Street1',
                              friendlyName: 'Street 1',
                              type: 'inputField'
                        },
                        {
                              name: 'CityName',
                              friendlyName: 'City Name',
                              type: 'inputField'
                        },
                        {
                              name: 'Country',
                              friendlyName: 'Country',
                              type: 'inputField'
                        },
                        {
                              name: 'CountryName',
                              friendlyName: 'Country Name',
                              type: 'inputField'
                        },
                        {
                              name: 'Phone',
                              friendlyName: 'Phone',
                              type: 'inputField'
                        },
                        {
                              name: 'PostalCode',
                              friendlyName: 'Postal Code',
                              type: 'inputField'
                        }
                  ]
            }
      ]);
};

export const invoiceFields = [
      {
            name: 'invoice',
            type: 'invoice',
            friendlyName: 'Invoice Data',
            content: [
                  { name: 'Email', friendlyName: 'Email', type: 'inputField' },
                  { name: 'salesPrice', friendlyName: 'Sales Price', type: 'inputField' },
                  {
                        name: 'salesDate',
                        friendlyName: 'Date of Sales',
                        type: 'inputField'
                  }
            ]
      }
];
