import React, { useEffect, useState, useRef } from 'react';
import { saveChanges } from '../../controller/saveChanges';
import { handleStatusChange } from './statusChangeHandler';
import { TopOptions } from './TopOptions';
import { SalesTable } from './SalesTable';
import { requestData } from '../../controller/socketIOOperations';
import { fieldNameFunction } from './fieldNames';
import RingLoader from 'react-spinners/RingLoader';

export function SalesPage() {
      const totalProfitsRef = useRef([]);
      const [fieldNames, setFieldNames] = useState([]);
      const [data, setData] = useState([]);
      const [inputValues, setInputValues] = useState({ searchOnAdID: '' });
      const [receiveInvoiceDataObject, setReceiveInvoiceDataObject] = useState();
      const [purchaseData, setPurchaseData] = useState([{ _id: 1, isFolded: true }]);
      const [openMenus, setOpenMenus] = useState({});
      const [soldIsChecked, setSoldIsChecked] = useState(false);
      const [ebayData, setEbayData] = useState([]);
      const [isLoaded, setIsLoaded] = useState(false);
      const [showContent, setShowContent] = useState(false); // State to control fade-in
      const [isTriggered, setIsTriggered] = useState(false);

      // useEffect(() => {
      //       console.log(data, 'fck');
      // }, [data]);

      // Watch for changes in isLoaded and trigger the fade-in effect
      useEffect(() => {
            if (isLoaded) {
                  const timeout = setTimeout(() => {
                        setShowContent(true);
                  }, 50);
                  return () => clearTimeout(timeout);
            }
      }, [isLoaded]); // Dependency on isLoaded so it runs whenever isLoaded changes

      useEffect(() => {
            if (ebayData && purchaseData) {
                  ebayData.forEach((ebayItem) => {
                        const ebayItemId = Number(ebayItem.TransactionArray.Transaction[0].Item.ItemID);

                        const matchedPurchase = purchaseData.find((purchaseItem) => Number(purchaseItem.ebayAdId) === ebayItemId);

                        if (matchedPurchase) {
                              if (!matchedPurchase?.EbaySale && ebayItem?.MonetaryDetails?.Payments?.Payment?.PaymentStatus === 'Succeeded') {
                                    matchedPurchase.status = '7.sold (Not shipped)';
                                    saveChanges([{ ...matchedPurchase, EbaySale: ebayItem?.OrderID }], false, true, false);
                              }
                        }
                  });
            }
            setEbayData(ebayData);
      }, [ebayData.length]);

      useEffect(() => {
            !isTriggered &&
                  (async function () {
                        setIsTriggered(true);
                        // receiveValues(setPurchaseData, setData, setFieldNames, setEbayData, soldIsChecked); NOTENOTE
                        const collections = await requestData([
                              'paypalTransactions',

                              {
                                    collection: 'ebaySales',
                                    altParams: {
                                          projection: {
                                                'TransactionArray.Transaction.Item.Title': 1,
                                                OrderID: 1,
                                                'TransactionArray.Transaction.Status.ReturnStatus': 1,
                                                'CheckoutStatus.Status': 1,
                                                CancelStatus: 1,
                                                'TransactionArray.Transaction.TransactionPrice.value': 1,
                                                'TransactionArray.Transaction.Item.ItemID': 1
                                          }
                                    }
                              },
                              {
                                    collection: 'purchases',
                                    altParams: {
                                          projection: {
                                                priceComparisonLink: 0,
                                                adText: 0,
                                                aiGeneratedInfo: 0,
                                                description: 0,
                                                HTMLObjectJSON: 0
                                          }
                                    }
                              },
                              {
                                    collection: 'invoices',
                                    altParams: {
                                          projection: {
                                                Buyer: 0,
                                                ebayObj: 0
                                          }
                                    }
                              }
                        ]);

                        setPurchaseData(collections.collectionsData.purchases);

                        setEbayData(collections.collectionsData.ebaySales);

                        setReceiveInvoiceDataObject(collections.collectionsData.invoices);

                        fieldNameFunction(
                              collections.collectionsData.ebaySales.map((item) => ({
                                    title: item.TransactionArray?.Transaction[0]?.Item?.Title ?? 'Default Title',
                                    productId: item.OrderID,
                                    purchaseStatus: item.TransactionArray?.Transaction[0]?.Status.ReturnStatus,
                                    paymentStatus: item.CheckoutStatus.Status,
                                    cancelStatus: item.CancelStatus
                              })),
                              setFieldNames
                        );

                        setIsLoaded(true);
                  })();
      }, []);

      useEffect(() => {}, [data, ebayData, purchaseData]);

      const handleInputChange = async (e, index, field, isPurchaseData = false, objectAddress = false) => {
            if (e.target.name === 'status') {
                  handleStatusChange(e.target.value, index, purchaseData, setPurchaseData);
                  return;
            }
            if (isPurchaseData) {
                  const updatedPurchaseData = [...purchaseData];
                  if (objectAddress) {
                        // If objectAddress is provided, handle setting a nested property
                        const currentObject = updatedPurchaseData[index] || {};
                        if (!currentObject[objectAddress]) {
                              // Initialize the nested object if it does not exist
                              currentObject[objectAddress] = {};
                        }
                        currentObject[objectAddress][field] = e.target.value;
                        updatedPurchaseData[index] = currentObject;
                  } else {
                        // Default behavior to update the field directly on the object
                        updatedPurchaseData[index] = {
                              ...updatedPurchaseData[index],
                              [field]: e.target.value
                        };
                  }

                  saveChanges(updatedPurchaseData, setPurchaseData, true);
            } else {
                  setInputValues({ ...inputValues, [e.target.name]: e.target.value });
            }
      };

      const toggleFoldableMenu = (index) => {
            setOpenMenus((prevOpenMenus) => {
                  const updatedOpenMenus = {};
                  Object.keys(prevOpenMenus).forEach((menuIndex) => {
                        updatedOpenMenus[menuIndex] = false; // Close all menus
                  });
                  updatedOpenMenus[index] = !prevOpenMenus[index]; // Toggle the selected menu
                  return updatedOpenMenus;
            });
      };

      // useEffect(() => {
      //       const performSort = () => {
      //             const sortedData = sortOnLastStatusChange(purchaseData);

      //             if (JSON.stringify(sortedData) !== JSON.stringify(purchaseData)) {
      //                   setPurchaseData(sortedData);
      //                   setSortApplied(true);
      //             }
      //       };
      //       if (purchaseData.length > 1 && !sortApplied) {
      //             performSort();
      //       }
      //       // eslint-disable-next-line react-hooks/exhaustive-deps
      // }, [purchaseData.length, sortApplied]);

      if (isLoaded) {
            return (
                  <div
                        style={{
                              opacity: showContent ? 1 : 0,
                              transition: 'opacity 0.2s ease-in-out',
                              visibility: showContent ? 'visible' : 'hidden',
                              height: '100vh' // Ensure the div takes full height
                        }}
                  >
                        <TopOptions
                              setSoldIsChecked={setSoldIsChecked}
                              inputValues={inputValues}
                              handleInputChange={handleInputChange}
                              data={data}
                              setData={setData}
                              purchaseData={purchaseData}
                              soldIsChecked={soldIsChecked}
                              totalProfitsRef={totalProfitsRef}
                              setPurchaseData={setPurchaseData}
                        />
                        {receiveInvoiceDataObject && purchaseData && (
                              <SalesTable
                                    fieldNames={fieldNames}
                                    purchaseData={purchaseData}
                                    toggleFoldableMenu={toggleFoldableMenu}
                                    handleInputChange={handleInputChange}
                                    receiveInvoiceDataObject={receiveInvoiceDataObject}
                                    totalProfitsRef={totalProfitsRef}
                                    openMenus={openMenus}
                                    soldIsChecked={soldIsChecked}
                                    setPurchaseData={setPurchaseData}
                                    ebayData={ebayData}
                                    setReceiveInvoiceDataObject={setReceiveInvoiceDataObject}
                              />
                        )}
                  </div>
            );
      } else {
            return (
                  <div
                        style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100vh',
                              transition: 'opacity 0.2s ease-out'
                        }}
                  >
                        <RingLoader color="#36d7b7" size={600} />
                  </div>
            );
      }
}
