import React, { useState, useEffect, useCallback } from 'react';
import { calculateProfit } from '../../helpers/calculateProfit.js';
import { saveChanges } from '../../controller/saveChanges';
import { updateProfits } from '../../helpers/updateProfits.js';
import { v4 as uuidv4 } from 'uuid';
import { DebouncedInput } from '../../helpers/DebouncedInput.js';

function findColorByValue(value, options) {
      // Find the option that matches the value and return its color, default to 'transparent' if not found
      const matchingOption = options.find((option) => option.value === value);
      return matchingOption ? matchingOption.color : 'white';
}

export const RenderSalesInputRows = ({ item, index, field, toggleFoldableMenu, handleInputChange, purchaseData, receiveInvoiceDataObject, totalProfitsRef, setPurchaseData }) => {
      if (!item || !field) {
            return null;
      }
      switch (field.type) {
            case 'foldableMenu':
                  return (
                        <td key={field.name}>
                              <button onClick={() => toggleFoldableMenu(index)} className="btn btn-primary" style={{ backgroundColor: '#1AA99D', borderColor: '#1AA99D', width: '90px' }}>
                                    Expand
                              </button>
                        </td>
                  );
            case 'inputField':
                  return (
                        <td key={field.name}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <DebouncedInput index={index} field={field} handleInputChange={handleInputChange} initialValue={item[field.name] || ''} disabled={field.name === 'adId'} />
                                    {field.name === 'adId' && (
                                          <button
                                                style={{
                                                      backgroundColor: '#1AA99D',
                                                      borderColor: '#1AA99D',
                                                      marginLeft: '5px',
                                                      padding: '2px 5px',
                                                      fontSize: '12px',
                                                      cursor: 'pointer',
                                                      color: 'white',
                                                      border: 'none',
                                                      borderRadius: '3px'
                                                }}
                                                onClick={() => {
                                                      window.open(`https://www.kleinanzeigen.de/s-anzeige/${item[field.name]}`, '_blank', 'noopener,noreferrer');
                                                }}
                                          >
                                                Visit
                                          </button>
                                    )}
                              </div>
                        </td>
                  );

            case 'dropdown':
                  return (
                        <td key={field.name}>
                              <select
                                    name={field.name}
                                    value={item[field.name] || ''}
                                    style={{
                                          backgroundColor: findColorByValue(item[field.name], field.dropDownOptions)
                                    }}
                                    onChange={(e) => {
                                          handleInputChange(e, index, field.name, true);
                                    }}
                              >
                                    <option value="">Select Option</option>
                                    {field.dropDownOptions &&
                                          field.dropDownOptions
                                                .filter((option) => {
                                                      // Check if option is an object or a string and apply corresponding checks
                                                      if (typeof option === 'object' && !option.color) {
                                                            const isAssigned = purchaseData.some((dataItem) => dataItem[field.name] && dataItem[field.name] === option.productId);
                                                            const isHealthy =
                                                                  // option.total.value !== 0 &&
                                                                  option.purchaseStatus === 'NotApplicable' && option.cancelStatus === 'NotApplicable' && option.productId.match(/^\d{2}-\d{5}-\d{5}$/);
                                                            const isCurrentlyAssigned = item[field.name] === option.productId;
                                                            const isCover =
                                                                  option.title.includes('Schutzpaket 2 in 1') || option.title.includes('Schutzpaket 3') || option.title.includes('Displayschutzglas');
                                                            return (!isAssigned || isCurrentlyAssigned) && isHealthy && !isCover;
                                                      } else {
                                                            // Strings don't have 'productId', 'purchaseStatus', or 'cancelStatus'
                                                            // So you might want to return true or apply other necessary logic based on your needs
                                                            return true;
                                                      }
                                                })
                                                .map((option, optionIndex) => {
                                                      // Determine display text and value based on whether option is an object or a string
                                                      const displayText = typeof option === 'object' && !option.color && option.title ? `${option.productId}: ${option.title}` : option.value;
                                                      const value = typeof option === 'object' && !option.color && option.productId ? option.productId : option.value;
                                                      return (
                                                            <option key={`option-${optionIndex}`} value={value} style={{ backgroundColor: option.color ? option.color : 'transparent' }}>
                                                                  {displayText}
                                                            </option>
                                                      );
                                                })}
                              </select>
                        </td>
                  );

            case 'datepicker':
                  return (
                        <td key={uuidv4()}>
                              <input
                                    type="date"
                                    name={field.name}
                                    value={item[field.name] || ''}
                                    onChange={(e) => {
                                          handleInputChange(e, index, field.name, true);
                                    }}
                                    onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                                //e.preventDefault();  // Prevents the form from being submitted
                                                saveChanges([{ ...purchaseData[index] }], false, true);
                                          }
                                    }}
                                    onBlur={() => {
                                          // Call the saveChanges function when the input loses focus
                                          saveChanges([{ ...purchaseData[index] }], false, true);
                                    }}
                              />
                        </td>
                  );
            case 'lastUpdate':
                  return (
                        <td key={uuidv4()}>{`${purchaseData[index]?.statusChanges ? purchaseData[index]?.statusChanges[purchaseData[index]?.statusChanges?.length - 1].date : 'not updated yet'}`}</td>
                  );
            case 'profit':
                  return (
                        <td key={uuidv4()}>
                              {receiveInvoiceDataObject
                                    .map((invoice) => {
                                          if (invoice.invoiceNr === item.invoiceNr) {
                                                updateProfits(invoice, item, totalProfitsRef);
                                                return Number(calculateProfit(invoice.ebayObj.Subtotal.value, totalProfitsRef)) - Number(item.amountPayed); // Return price when invoiceNr matches
                                          }
                                          return null; // Return null or any other value for non-matching items
                                    })
                                    .filter((price) => price !== null)}
                        </td>
                  );
            case 'button':
                  return (
                        <td key={uuidv4()}>
                              <button
                                    onClick={() => saveChanges([{ ...purchaseData[index], deleted: true }], false, true)}
                                    className="btn btn-primary"
                                    style={{ backgroundColor: '#1AA99D', borderColor: '#1AA99D', width: '90px' }}
                              >
                                    Delete
                              </button>
                        </td>
                  );

            default:
                  return null;
      }
};
