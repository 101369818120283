import { io } from 'socket.io-client';
import { fieldNameFunction } from './fieldNames';
import { requestData } from '../../controller/socketIOOperations';
import { saveChanges } from '../../controller/saveChanges';

function getNextInvoiceNumber(invoices) {
      let highestNumber = 0;

      invoices.forEach((invoiceObj) => {
            // Assuming the key that holds the invoice number is named "invoiceNumber"
            const invoiceString = invoiceObj.invoiceNr;
            const numberPart = parseInt(invoiceString.split('-')[1], 10);

            if (numberPart > highestNumber) {
                  highestNumber = numberPart;
            }
      });

      const nextNumber = highestNumber + 1;
      // Assuming all invoices are from the same year for simplicity
      const yearPart = invoices.length > 0 ? invoices[0].invoiceNr.split('-')[0] : '2024';

      return `${yearPart}-${nextNumber.toString().padStart(4, '0')}`;
}

export const createHTML = (item) => {
      const socket = io(process.env.REACT_APP_ENDPOINT);

      socket.emit('createHTML', JSON.stringify([item]), () => {
            socket.disconnect();
      });
};

export const createReturn = (item, setPurchaseData) => {
      renameKeys(item, ['ShippingAddress', 'invoiceNr']);
      handleStatusChangeNew('4.received', item, setPurchaseData);
      saveChanges([item], setPurchaseData, true, true);
};

const renameKeys = (obj, keysToRename) => {
      keysToRename.forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                  let newKey = key + '_old';
                  let counter = 2;

                  // Check if the new key already exists and find a unique key name
                  while (Object.prototype.hasOwnProperty.call(obj, newKey)) {
                        newKey = key + '_old' + counter;
                        counter++;
                  }

                  // Rename the key
                  obj[newKey] = obj[key];

                  // Set the original key to null to represent deletion
                  obj[key] = null;
            }
      });
};

export const buildEbay = (item, setPurchaseData) => {
      const socket = io(process.env.REACT_APP_ENDPOINT);

      socket.emit('buildEbayAd', JSON.stringify([item]), (response) => {
            if (response.status === 'success') {
                  saveChanges([response.data], setPurchaseData, true, false);
            } else {
                  console.error('Failed to create Ebay ad Label:', response.message);
            }
            alert(response.message);
            socket.disconnect();
      });
};

export const updateEbay = (item) => {
      const socket = io(process.env.REACT_APP_ENDPOINT);

      socket.emit('updateEbayAd', JSON.stringify([item]), () => {
            socket.disconnect();
      });
};

export const CreateDHLLabel = (item, setPurchaseData) => {
      const socket = io(process.env.REACT_APP_ENDPOINT);

      // Emit with data and handle the response in callback
      socket.emit('CreateDHLLabel', JSON.stringify([item]), (response) => {
            if (response.status === 'success') {
                  response.data[0].error ? alert(response.data[0].error) : saveChanges([response.data[0]], setPurchaseData, true, false);
                  // Here you can handle the received data

                  // Optionally, update state or pass data to another function/component
            } else {
                  console.error('Failed to create DHL Label:', response.message);
            }

            socket.disconnect();
      });
};

export const createInvoice = async (item, setReceiveInvoiceDataObject) => {
      if (!item.EbaySale) {
            alert('first connect to an ebay sale');
            return;
      }
      if (!item.IMEI) {
            alert('first add an IMEI Number');
            return;
      }
      if (item.invoiceNr) {
            alert('Invoice already exists');
            return;
      }
      console.log('incoive triggered');
      const collections = await requestData([
            {
                  collection: 'invoices',
                  altParams: {
                        projection: {
                              Buyer: 0,
                              ebayObj: 0
                        }
                  }
            }
      ]);
      setReceiveInvoiceDataObject(collections.collectionsData.invoices);

      const nextNr = getNextInvoiceNumber(collections.collectionsData.invoices);

      const socket = io(process.env.REACT_APP_ENDPOINT);
      item.invoiceNr = nextNr;

      const invoices = await new Promise((resolve) => {
            socket.emit('createInvoice', JSON.stringify([item]), (invoices) => {
                  socket.disconnect();
                  resolve(invoices);
            });
      });
      return invoices;
};

export const receiveValues = (setPurchaseData, setData, setFieldNames, setEbayData, soldIsChecked) => {
      const socket = io(process.env.REACT_APP_ENDPOINT);
      console.log('test');

      // Handle 'purchases' event and cleanup
      socket.on('purchases', (pricesData) => {
            setPurchaseData(pricesData);
      });
      socket.off('purchases', (pricesData) => {
            setPurchaseData(null); // or any specific cleanup logic for this event
      });

      // // Handle 'successListings' event and cleanup
      // socket.on('successListings', (pricesData) => {
      //       console.log('are you!!!', pricesData);
      //       setData(pricesData);
      // });
      // socket.off('successListings', (pricesData) => {
      // });

      // Handle 'ebaySales' event and cleanup
      let isEbaySalesHandled = false;
      socket.on('ebaySales', (ebayData) => {
            if (!isEbaySalesHandled) {
                  console.log('ebayData', ebayData);
                  setEbayData(ebayData);
                  fieldNameFunction(
                        ebayData.map((item) => ({
                              title: item.TransactionArray?.Transaction[0]?.Item?.Title ?? 'Default Title',
                              productId: item.OrderID,
                              purchaseStatus: item.TransactionArray?.Transaction[0]?.Status.ReturnStatus,
                              paymentStatus: item.CheckoutStatus.Status,
                              cancelStatus: item.CancelStatus
                        })),
                        setFieldNames
                  );
                  isEbaySalesHandled = true;
            }
      });
      socket.off('ebaySales', (ebayData) => {});
};

export const receiveInvoiceData = async () => {
      const socket = io(process.env.REACT_APP_ENDPOINT);
      // Wrap the asynchronous operation in a promise

      const promise = new Promise((resolve) => {
            socket.on('invoices', (invoices) => {
                  resolve(invoices); // Resolve the promise with the invoices data
                  socket.off('invoices'); // Clean up the event listener
            });
      });

      const invoices = await promise; // Wait for the promise to resolve
      return invoices; // Return the received invoices data
};

export const receiveEbayData = async () => {
      const socket = io(process.env.REACT_APP_ENDPOINT);
      // Wrap the asynchronous operation in a promise
      const promise = new Promise((resolve) => {
            socket.on('ebaySales', (ebaySales) => {
                  resolve(ebaySales); // Resolve the promise with the ebaySales data
                  socket.off('ebaySales'); // Clean up the event listener
            });
      });

      const ebaySales = await promise; // Wait for the promise to resolve
      return ebaySales; // Return the received ebaySales data
};

export const processInput = async (inputValues, data, setData = false, setPurchaseData = false) => {
      const searchedAdId = inputValues.searchOnAdID;
      let foundItem = false;
      const result = await requestData([{ collection: 'successListings', altParams: { baseQuery: { adId: searchedAdId } } }]);

      foundItem = result.collectionsData['[object Object]'][0];

      if (foundItem) {
            // const socket = io(process.env.REACT_APP_ENDPOINT);
            foundItem.ProductName = foundItem.aiGeneratedInfo.ProductName;
            foundItem.ebayTitle = foundItem.aiGeneratedInfo.ProductName;
            if (data) {
                  foundItem.paymentData = data;
            }
            //       socket.emit('purchase', JSON.stringify([foundItem]), () => {
            //             socket.disconnect();
            //       });
            //      setData((prevState) => [...prevState, foundItem]);
            saveChanges([foundItem], setPurchaseData, true);
      } else {
            const newItem = {
                  adId: searchedAdId
            };
            const socket = io(process.env.REACT_APP_ENDPOINT, {
                  path: '/socket.io',
                  transports: ['websocket', 'polling'] // Prefer WebSocket but allow polling fallback
            });

            socket.emit('purchase', JSON.stringify([newItem]), () => {
                  socket.disconnect();
            });
            setData && setData((prevState) => [...prevState, newItem]);
      }
};
