import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { receiveIndividualProduct } from '../../controller/socketIOOperations';
import styles from './IndividualProductPage.module.css';
import { PriceInputFIeld } from '../shared/InputField';
import { receivePriceLists } from '../../controller/socketIOOperations';
import io from 'socket.io-client';
import { processInput } from '../salesPage/socketIOOperations';
import { requestData } from '../../controller/socketIOOperations';

export function IndividualProductPage() {
      const { productNameOrID } = useParams();
      const [productInfo, setProductInfo] = useState(null);
      const [data, setData] = useState(false);
      const [isSaving, setIsSaving] = useState(false);
      const [email,setEmail] = useState("")
      const [price,setPrice] = useState(0)


      useEffect(() => {
            async function fetchProductInfo() {
                  if (/^\d{10}$/.test(productNameOrID)) {
                        const productData = await receiveIndividualProduct(productNameOrID);
                      

                        setProductInfo(productData[0]);
                  }
            }

            fetchProductInfo();
      }, [productNameOrID]);

      useEffect(() => {
            if (productInfo?.document) {
                  (async () => {
                     
                        const checkup = await receivePriceLists(productInfo?.document.productgroup,productInfo.document.aiGeneratedInfo.ProductName);
                      

                        checkup.data
                              .filter((item) => item.ProductName === productInfo.document.aiGeneratedInfo.ProductName)
                              .forEach((item) => {
                                    setData([item]);
                              });
                  })();
            }
      }, [productInfo]);

      const handleDamageReportChange = (key, value) => {
            const updatedDamageReport = {
                  ...productInfo.document.aiGeneratedInfo.damageReport,
                  [key]: value
            };

            setProductInfo((prevInfo) => ({
                  ...prevInfo,
                  document: {
                        ...prevInfo.document,
                        aiGeneratedInfo: {
                              ...prevInfo.document.aiGeneratedInfo,
                              damageReport: updatedDamageReport
                        }
                  }
            }));
      };
      const handleNoteChange = (value) => {
            setProductInfo((prevInfo) => ({
                  ...prevInfo,
                  document: {
                        ...prevInfo.document,
                        aiGeneratedInfo: {
                              ...prevInfo.document.aiGeneratedInfo,
                              damageReport: {
                                    ...prevInfo.document.aiGeneratedInfo.damageReport,
                                    notes: value
                              }
                        }
                  }
            }));
      };


  
        

      if (productInfo && data[0]) {
            const damageReportEntries = Object.entries(productInfo.document.aiGeneratedInfo.damageReport || {});
         
            const inputValues = { searchOnAdID: productInfo.document.adId };

            const handleBuy = async () => {
                  const paymentData = {
                      email: email,
                      amount: price ? price : productInfo.document.price,
                      currency: "EUR",
                      itemName: `${productInfo.document.aiGeneratedInfo.ProductName}, KleinanzeigenID: ${productInfo.document.adId} link: https://www.kleinanzeigen.de/s-anzeige/${productInfo.document.adId}`,
                      shippingPrompt: 2,
                      baseUrl: "https://www.paypal.com/cgi-bin/webscr",
                      cmd: "_xclick",
                      noShipping: 2,
                      afterPayLink: `https://yourgreenphone.de/sales/afterPay/${productInfo.document.adId}`
                  };
              
                  await processInput(inputValues,paymentData);
              
                  // Redirect to PayPal payment page
                  window.location.href = `${paymentData.baseUrl}?cmd=${paymentData.cmd}&business=${encodeURIComponent(paymentData.email)}&amount=${paymentData.amount}&currency_code=${paymentData.currency}&item_name=${encodeURIComponent(paymentData.itemName)}&no_shipping=${paymentData.noShipping}&return=${encodeURIComponent(paymentData.afterPayLink)}`;
              };

            return (
                  <div className={styles.individualProductPage}>
                        <div className={styles.header}>
                              <h1>General product info</h1>
                        </div>
                        <div className={styles.productInfo}>
                              <h2 className={styles.productTitle}>AI Compared title: {productInfo.document.aiGeneratedInfo.ProductName}</h2>
                              <div className={styles.productDetails}>
                                    <p>
                                          Average Sales Price: <PriceInputFIeld product={data[0]} setData={setData} setIsSaving={setIsSaving} productgroup={productInfo.document.productgroup} /> Euro
                                    </p>
                              </div>
                        </div>
                        <div className={styles.aiTraining}>
                              <h1>For AI Training</h1>
                              <h2 className={styles.originalTitle}>Original Title: {productInfo.document.productTitle}</h2>
                              <div className={styles.trainingDetails}>
                                    <p>Price: {productInfo.document.price} Euro</p>
                                    <p>Description: {productInfo.document.adText}</p>
                                    {damageReportEntries.map(
                                          ([key, value]) =>
                                                key !== 'notes' && (
                                                      <div key={key}>
                                                            <label>
                                                                  <input
                                                                        type="checkbox"
                                                                        className={styles.checkboxInput}
                                                                        checked={value}
                                                                        onChange={(e) => handleDamageReportChange(key, e.target.checked)}
                                                                  />
                                                                  {key}
                                                            </label>
                                                      </div>
                                                )
                                    )}
                                    <div className={styles.notesSection}>
                                          <label htmlFor="damageNotes">Notes:</label>
                                          <textarea
                                                id="damageNotes"
                                                className={styles.notesInput}
                                                placeholder="Add any notes here..."
                                                value={
                                                      productInfo.document.aiGeneratedInfo &&
                                                      productInfo.document.aiGeneratedInfo.damageReport &&
                                                      productInfo.document.aiGeneratedInfo.damageReport.notes
                                                            ? productInfo.document.aiGeneratedInfo.damageReport.notes
                                                            : ''
                                                }
                                                onChange={(e) => handleNoteChange(e.target.value)}
                                          ></textarea>
                                    </div>
                              </div>
                        </div>
                        <div className={styles.productInfo}>
    <div className={styles.productDetails} 
    style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <p>
            <label htmlFor="email">Paypal Email:</label>
            <input
                type="email"
                id="email"
                placeholder=""
                onChange={(e) => setEmail(e.target.value)} // Assuming setEmail updates state
                style={{ margin: '10px 0', padding: '10px', width: '200px' }}
            />
        </p>
        <p>
            <label htmlFor="price">Price:</label>
            <input
                type="number"
                id="price"
                placeholder={productInfo.document.price}
                onChange={(e) => setPrice(e.target.value)} // Assuming setPrice updates state
                style={{ margin: '10px 0', padding: '10px', width: '60px' }}
            />
        </p>
        <p>
            <button
                onClick={() => handleBuy()}
                className="btn btn-primary"
                style={{ backgroundColor: '#1AA99D', borderColor: '#1AA99D', width: '110px' }}
            >
                Buy
            </button>
        </p>
    </div>
</div>

<div><ul>
          <li>backplate iphone 10-12 minus 50</li>
          <li>backplate iphone 13-14 minus 100</li>
          <li>backplate Samsung minus 30</li>
          <li>usb minus 30</li>
          <li>speaker low minus 10</li>
          <li>speaker broken minus 30%</li>
       <li>Price increase for memory only applys on iphone</li>
            <li>min memory for iphone 11-12 = 64GB, min memory from 12Pro = 128GB</li>
            <li>if increase in memory you can add 10% per increase *2</li>
      
        </ul></div>
                        
                        
                  </div>
            );
      }

      return (
            <div className={styles.individualProductPage}>
                  <div className={styles.header}>
                        <h1>Individual Product Page</h1>
                        <p>Loading product information...</p>
                  </div>
            </div>
      );
}
