import { io } from "socket.io-client";


export const receiveEbayMessages = async () => {
    const socket = io(process.env.REACT_APP_ENDPOINT, {
        path: "/socket.io",
        transports: ['websocket', 'polling'], // Prefer WebSocket but allow polling fallback
  });
  const promise = new Promise((resolve) => {
  socket.emit('ebayMessages', JSON.stringify(["hello"]), (ebayMessages) => {
    resolve(ebayMessages); 
    socket.disconnect()
})})

    // Wrap the asynchronous operation in a promise
    // const promise = new Promise((resolve) => {
    //       socket.on('ebayMessages', (ebaySales) => {
    //             resolve(ebaySales); // Resolve the promise with the ebaySales data
    //             socket.off('ebayMessages'); // Clean up the event listener
    //       });
    // });

    const ebayMessages = await promise; // Wait for the promise to resolve
    return ebayMessages; // Return the received ebaySales data
};