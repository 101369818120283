// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-content {
  padding: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

td a {
  color: blue;
  text-decoration: none;
}

.sticky-button {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's above other elements */
  background-color: white; /* Or any desired background */
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: for better visibility */
}
`, "",{"version":3,"sources":["webpack://./src/views/productPage/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,gBAAgB;AAClB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,aAAa,EAAE,qCAAqC;EACpD,uBAAuB,EAAE,8BAA8B;EACvD,aAAa;EACb,wCAAwC,EAAE,oCAAoC;AAChF","sourcesContent":[".App-content {\n  padding: 20px;\n}\n\ntable {\n  border-collapse: collapse;\n  width: 100%;\n  margin-top: 20px;\n}\n\nth,\ntd {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\nth {\n  background-color: #f2f2f2;\n}\n\ntr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n\ntd a {\n  color: blue;\n  text-decoration: none;\n}\n\n.sticky-button {\n  position: sticky;\n  top: 0;\n  z-index: 1000; /* Ensure it's above other elements */\n  background-color: white; /* Or any desired background */\n  padding: 10px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: for better visibility */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
