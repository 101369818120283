import { io } from 'socket.io-client'


export const receivePaypalData = async () => {
  const socket = io(process.env.REACT_APP_ENDPOINT)
  // Wrap the asynchronous operation in a promise
  const promise = new Promise((resolve) => {
    socket.on('paypalTransactions', (transactions) => {
      resolve(transactions) // Resolve the promise with the invoices data
      socket.off('paypalTransactions') // Clean up the event listener
    })
  })

  const invoices = await promise // Wait for the promise to resolve
  return invoices // Return the received invoices data
}
