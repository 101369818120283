import React, { useState } from 'react';
import { saveChanges } from '../controller/saveChanges';

function FileUploadForm({ currentProductObj }) {
      const [files, setFiles] = useState([]);

      const handleFileChange = (e) => {
            const selectedFiles = Array.from(e.target.files);
            setFiles(selectedFiles);
      };

      const handleSubmit = async (e) => {
            e.preventDefault();

            if (files.length === 0) {
                  alert('Please select one or more files first!');
                  return;
            }
            const formData = new FormData();
            files.forEach((file) => {
                  formData.append('images', file);
            });

            try {
                  const response = await fetch('https://yourgreenphone.de/upload', {
                        method: 'POST',
                        body: formData
                  });

                  if (response.ok) {
                        const data = await response.text();
                        alert('Images uploaded successfully');
                        let parsedData = JSON.parse(data);

                        currentProductObj.images = parsedData.files;
                        saveChanges([currentProductObj], false, true);
                  } else {
                        alert('Upload failed');
                  }
            } catch (error) {
                  console.error('Error:', error);
                  alert('Upload error');
            }
      };

      return (
            <form onSubmit={handleSubmit} className="mb-3">
                  <div className="input-group" style={{ maxWidth: '350px' }}>
                        {' '}
                        {/* Set a maximum width for the input group */}
                        <input type="file" name="images" className="form-control" multiple onChange={handleFileChange} accept="image/*" style={{ cursor: 'pointer' }} />
                        <button
                              type="submit"
                              className="btn"
                              style={{
                                    backgroundColor: '#1AA99D',
                                    borderColor: '#1AA99D'
                              }}
                        >
                              Upload
                        </button>
                  </div>
            </form>
      );
}

export default FileUploadForm;
