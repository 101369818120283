import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function ForwardPush() {
  const { text, adId } = useParams();

  useEffect(() => {
    // Decode URI components in case they include spaces or special characters
    const decodedText = decodeURIComponent(text);
  

    navigator.clipboard.writeText(decodedText)
      .then(() => {
        console.log('Text successfully copied to clipboard');
        window.location.href = `https://www.kleinanzeigen.de/s-anzeige/${adId}`; // Redirect after successful copy
      })
      .catch(err => {
        console.error('Failed to copy text:', err);
        window.location.href = `https://www.kleinanzeigen.de/s-anzeige/${adId}`; // Redirect even if copy fails
      });
  }, [text, adId]); // Dependency array ensures effect runs when text or hyperlink changes

  return (
    <div>
      <p>Copying text to your clipboard and redirecting...</p>
    </div>
  );
}

