import { io } from 'socket.io-client';

let saveTimeout;
export const saveChanges = (arrayOfPurchases, setPurchaseData = false, noDelay = false, sendToServer = true) => {
      console.log('Saving changes:');
      if (setPurchaseData) {
            setPurchaseData((prevState) => {
                  if (arrayOfPurchases.length === 1) {
                        const existingIndex = prevState.findIndex((purchase) => purchase.adId === arrayOfPurchases[0].adId);
                        if (existingIndex !== -1) {
                              // Overwrite the existing item
                              const updatedPurchases = [...prevState];
                              updatedPurchases[existingIndex] = arrayOfPurchases[0];
                              return updatedPurchases;
                        } else {
                              // Append the new item
                              return [...prevState, arrayOfPurchases[0]];
                        }
                  } else {
                        // If arrayOfPurchases has more than one item, replace or extend the whole array
                        return arrayOfPurchases;
                  }
            });
      }
      if (saveTimeout) clearTimeout(saveTimeout);
      saveTimeout = setTimeout(
            () => {
                  const dataToSend = arrayOfPurchases.map(({ ...item }) => item);

                  if (sendToServer) {
                        return new Promise((resolve, reject) => {
                              const socket = io(process.env.REACT_APP_ENDPOINT);

                              socket.emit('purchase', JSON.stringify(dataToSend), (response) => {
                                    socket.disconnect();
                                    // if (setPurchaseData) {
                                    //       setPurchaseData(arrayOfPurchases);
                                    // }
                                    resolve(response);
                              });
                              socket.on('connect_error', (error) => {
                                    console.error('Socket connection error:', error);
                                    socket.disconnect();
                                    reject(error);
                              });
                        });
                  } else {
                        return;
                  }
            },
            noDelay ? 1000 : 30000
      );
};
