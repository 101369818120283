import React, { useState, useEffect } from 'react'
import { DndContext } from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { saveChanges } from '../controller/saveChanges'

const onUpdate = async (newImages, fileArray) => {
  fileArray.images = newImages
  await saveChanges([fileArray])
}

function ImageList({ fileArray }) {
  const [images, setImages] = useState(
    fileArray.images.map((image, index) => ({
      ...image,
      id: image.id || `image${index}`,
    }))
  )

  useEffect(() => {
    setImages(
      fileArray.images.map((image, index) => ({
        ...image,
        id: image.id || `image${index}`,
      }))
    )
  }, [fileArray.images])

  const handleDragEnd = (event) => {
    const { active, over } = event
    if (over && active.id !== over.id) {
      setImages((currentImages) => {
        const oldIndex = currentImages.findIndex(
          (image) => image.id === active.id
        )
        const newIndex = currentImages.findIndex(
          (image) => image.id === over.id
        )
        const newImages = arrayMove(currentImages, oldIndex, newIndex)
        onUpdate(newImages, fileArray)
        return newImages
      })
    }
  }

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={images.map((image) => image.id)}>
        <ul style={{ display: 'flex', padding: 0 }}>
          {images.map((image) => (
            <DraggableImage
              key={image.id}
              id={image.id}
              src={image.uploadedName}
            />
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  )
}

function DraggableImage({ id, src }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    height: '100px',
    width: '100px',
    objectFit: 'cover',
    margin: '0 5px',
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <li
      ref={setNodeRef}
      style={{ display: 'inline-flex' }}
      {...attributes}
      {...listeners}
    >
      <img src={`https://yourgreenphone.de/${src}`} alt={`${src}`} style={style} />
    </li>
  )
}

export default ImageList
