// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-container {
    font-family: Arial, sans-serif;
    max-width: 960px;
    margin: auto;
    padding: 20px;
}

.logo {
    width: 60%;
    height: 120px;
    object-fit: cover;
    display: block;
}

.price-list {
    list-style-type: none;
    padding: 0;
}

.price-list li {
    padding: 8px 0;
    border-bottom: 1px solid #ccc;
}

.price-list li:last-child {
    border-bottom: none;
}

.navigation {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.nav-button button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
}

.nav-button button:hover {
    background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/views/mainPage/mainPage.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,gBAAgB;IAChB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".app-container {\n    font-family: Arial, sans-serif;\n    max-width: 960px;\n    margin: auto;\n    padding: 20px;\n}\n\n.logo {\n    width: 60%;\n    height: 120px;\n    object-fit: cover;\n    display: block;\n}\n\n.price-list {\n    list-style-type: none;\n    padding: 0;\n}\n\n.price-list li {\n    padding: 8px 0;\n    border-bottom: 1px solid #ccc;\n}\n\n.price-list li:last-child {\n    border-bottom: none;\n}\n\n.navigation {\n    margin-top: 20px;\n    display: flex;\n    justify-content: space-around;\n}\n\n.nav-button button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n    font-size: 16px;\n}\n\n.nav-button button:hover {\n    background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
