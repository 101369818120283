import React, { useRef } from 'react';
import { useJwt } from 'react-jwt';

const LabelPrinter = ({ DHLLabel }) => {
      const secretKey = 'lkdjfghniselujhgzsfhfpofpehgfojidsrugpgdosr';
      const { decodedToken, isExpired } = useJwt(secretKey);

      const iframeRef = useRef();

      const printInvoice = async () => {
            const token = process.env.REACT_APP_PRINTER_TOKEN;

            const fileUrl = `https://yourgreenphone.de/secure/label-${DHLLabel}.pdf`;
            const headers = new Headers({
                  Authorization: `Bearer ${encodeURIComponent(token)}`
            });

            try {
                  const response = await fetch(fileUrl, { headers });
                  if (response.ok) {
                        const blob = await response.blob();
                        const url = URL.createObjectURL(blob);
                        iframeRef.current.src = url;

                        iframeRef.current.onload = function () {
                              try {
                                    iframeRef.current.contentWindow.print();
                                    URL.revokeObjectURL(url); // Clean up URL object after printing
                              } catch (error) {
                                    console.error('Error printing the invoice:', error);
                              }
                        };
                  } else {
                        throw new Error('Failed to fetch the invoice');
                  }
            } catch (error) {
                  console.error('Error fetching the invoice:', error);
            }
      };

      return (
            <div>
                  <button className="btn btn-primary" style={{ backgroundColor: '#1AA99D', borderColor: '#1AA99D', width: '130px' }} onClick={printInvoice}>Print Shipping label</button>
                  <iframe ref={iframeRef} style={{ display: 'none' }}></iframe>
            </div>
      );
};

export default LabelPrinter;
