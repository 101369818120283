import { calculateProfit } from './calculateProfit';

export const updateProfits = (invoice, item, totalProfitsRef) => {
      const profit = Number(calculateProfit(invoice.ebayObj.Subtotal.value)) - Number(item.amountPayed);
      const currentProfits = totalProfitsRef.current;
      const index = currentProfits.findIndex((profitItem) => profitItem.invoiceNr === invoice.invoiceNr);

      if (index > -1) {
            currentProfits[index] = {
                  ...currentProfits[index],
                  pricePayed: item.amountPayed,
                  subtotalValue: invoice.ebayObj.Subtotal.value
            };
      } else if (!isNaN(profit)) {
            currentProfits.push({
                  invoiceNr: invoice.invoiceNr,
                  pricePayed: item.amountPayed,
                  subtotalValue: invoice.ebayObj.Subtotal.value
            });
      }
};
