import { useCallback, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

export const DebouncedInput = ({ index, field, handleInputChange, initialValue, disabled = false }) => {
      const [inputValue, setInputValue] = useState(initialValue);
      const [isChanged, setIsChanged] = useState(false); // Track if changes are made

      const debouncedUpdate = useCallback(
            debounce((value) => {
                  handleInputChange({ target: { name: field.name, value } }, index, field.name, true);
            }, 3000),
            [] // Ensure debounce function isn't recreated on each render
      );

      const handleChange = (event) => {
            setInputValue(event.target.value);
            setIsChanged(event.target.value !== initialValue); // Update the change state
            debouncedUpdate.cancel(); // Cancel the previous debounce
            debouncedUpdate(event.target.value); // Start a new debounce timer
      };

      const handleBlur = () => {
            if (isChanged) {
                  // Trigger only if changes have been made
                  handleInputChange({ target: { name: field.name, value: inputValue } }, index, field.name, true);
            }
      };

      useEffect(() => {
            setInputValue(initialValue);
            setIsChanged(false); // Reset change tracking when initialValue changes
            debouncedUpdate.cancel(); // Cancel any ongoing debounce if the initialValue changes
      }, [initialValue, debouncedUpdate]);

      return <input type="text" disabled={disabled} name={field.name} value={inputValue} onChange={handleChange} onBlur={handleBlur} style={{ backgroundColor: disabled ? 'lightgray' : 'white' }} />;
};
