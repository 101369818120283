// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roll-out-effect {
    overflow: hidden;
    max-height: 0;
    /* Start from no height */
    opacity: 0;
    /* Start from transparent */
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
}

.roll-out-effect.visible {
    max-height: 1200px;
    /* Sufficiently large to accommodate content, adjust as needed */
    opacity: 1;
    /* Fully opaque */
}`, "",{"version":3,"sources":["webpack://./src/views/salesPage/renderFields.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,UAAU;IACV,2BAA2B;IAC3B,iEAAiE;AACrE;;AAEA;IACI,kBAAkB;IAClB,gEAAgE;IAChE,UAAU;IACV,iBAAiB;AACrB","sourcesContent":[".roll-out-effect {\n    overflow: hidden;\n    max-height: 0;\n    /* Start from no height */\n    opacity: 0;\n    /* Start from transparent */\n    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;\n}\n\n.roll-out-effect.visible {\n    max-height: 1200px;\n    /* Sufficiently large to accommodate content, adjust as needed */\n    opacity: 1;\n    /* Fully opaque */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
