import React from 'react';
import { RenderSalesInputRows } from './RenderSalesInputRows';
import { DetailedField } from './RenderFields';
import { useState } from 'react';

import './tableStyles.css';

export function SalesTable({
      fieldNames,
      purchaseData,
      toggleFoldableMenu,
      handleInputChange,
      receiveInvoiceDataObject,
      totalProfitsRef,
      openMenus,
      soldIsChecked,
      setPurchaseData,
      ebayData,
      setReceiveInvoiceDataObject
}) {
      return (
            <table>
                  <thead>
                        <tr>
                              {fieldNames.map((field) => (
                                    <th key={field.name}>{field.friendlyName}</th>
                              ))}
                        </tr>
                  </thead>
                  <tbody>
                        {purchaseData.map((item, index) => {
                              if (item.deleted) return null;
                              const lastStatusChange = item.statusChanges?.[item.statusChanges.length - 1]?.status;
                              const shouldRender = !lastStatusChange || !(lastStatusChange.startsWith('x3') || lastStatusChange === '7.sold' || lastStatusChange.startsWith('e1'));
                              if (!shouldRender) return null;

                              return (
                                    <React.Fragment key={`${item}x${index}`}>
                                          <tr style={{ backgroundColor: item.status === '7.sold (Not shipped)' ? '#94B0AB' : '' }} key={item._id}>
                                                {fieldNames.map((field) => (
                                                      <RenderSalesInputRows
                                                            key={field.name}
                                                            item={item}
                                                            index={index}
                                                            field={field}
                                                            toggleFoldableMenu={toggleFoldableMenu}
                                                            handleInputChange={handleInputChange}
                                                            purchaseData={purchaseData}
                                                            receiveInvoiceDataObject={receiveInvoiceDataObject}
                                                            totalProfitsRef={totalProfitsRef}
                                                            setPurchaseData={setPurchaseData}
                                                      />
                                                ))}
                                          </tr>
                                          {openMenus[index] && fieldNames.some((field) => field.type === 'foldableMenu') && (
                                                <DetailedField
                                                      fieldNames={fieldNames}
                                                      item={item}
                                                      index={index}
                                                      handleInputChange={handleInputChange}
                                                      ebayData={ebayData}
                                                      setPurchaseData={setPurchaseData}
                                                      setReceiveInvoiceDataObject={setReceiveInvoiceDataObject}
                                                />
                                          )}
                                    </React.Fragment>
                              );
                        })}
                        <tr>
                              <td colSpan={fieldNames.length}>Sold & Refunded</td>
                        </tr>

                        {soldIsChecked &&
                              purchaseData.map((item, index) => {
                                    if (item.deleted) return null;
                                    const lastStatusChange = item.statusChanges?.[item.statusChanges.length - 1]?.status;
                                    const shouldRenderSecondList = lastStatusChange && (lastStatusChange.startsWith('x3') || lastStatusChange.startsWith('7'));
                                    if (!shouldRenderSecondList) return null;

                                    return (
                                          <React.Fragment key={`second-${item._id}-${index}`}>
                                                <tr>
                                                      {fieldNames.map((field) => (
                                                            <RenderSalesInputRows
                                                                  key={field.name}
                                                                  item={item}
                                                                  index={index}
                                                                  field={field}
                                                                  toggleFoldableMenu={toggleFoldableMenu}
                                                                  handleInputChange={handleInputChange}
                                                                  purchaseData={purchaseData}
                                                                  receiveInvoiceDataObject={receiveInvoiceDataObject}
                                                                  totalProfitsRef={totalProfitsRef}
                                                                  setPurchaseData={setPurchaseData}
                                                            />
                                                      ))}
                                                </tr>
                                                {openMenus[index] && fieldNames.find((field) => field.type === 'foldableMenu').content && (
                                                      <DetailedField
                                                            fieldNames={fieldNames}
                                                            item={item}
                                                            index={index}
                                                            handleInputChange={handleInputChange}
                                                            ebayData={ebayData}
                                                            setPurchaseData={setPurchaseData}
                                                            setReceiveInvoiceDataObject={setReceiveInvoiceDataObject}
                                                      />
                                                )}
                                          </React.Fragment>
                                    );
                              })}
                  </tbody>
            </table>
      );
}
