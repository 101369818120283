// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IndividualProductPage_individualProductPage__5oz7O {
    max-width: 960px;
    margin: auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.IndividualProductPage_header__gYI5j {
    text-align: center;
    margin-bottom: 40px;
}

.IndividualProductPage_productInfo__W6NZb,
.IndividualProductPage_aiTraining__xfysO {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.IndividualProductPage_productTitle__JuOi1,
.IndividualProductPage_originalTitle__ugO5J {
    color: #333;
    margin: 0;
    padding: 0;
    font-weight: bold;
}

.IndividualProductPage_productDetails__peyjT,
.IndividualProductPage_trainingDetails__aPAJo {
    margin: 10px 0;
}

.IndividualProductPage_productDetails__peyjT p,
.IndividualProductPage_trainingDetails__aPAJo p,
.IndividualProductPage_trainingDetails__aPAJo label {
    margin: 5px 0;
    color: #666;
}

.IndividualProductPage_checkboxInput__8EwZm {
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/views/individualProductPage/IndividualProductPage.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;;IAEI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;;IAEI,WAAW;IACX,SAAS;IACT,UAAU;IACV,iBAAiB;AACrB;;AAEA;;IAEI,cAAc;AAClB;;AAEA;;;IAGI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".individualProductPage {\n    max-width: 960px;\n    margin: auto;\n    padding: 20px;\n    font-family: 'Arial', sans-serif;\n}\n\n.header {\n    text-align: center;\n    margin-bottom: 40px;\n}\n\n.productInfo,\n.aiTraining {\n    background-color: #f5f5f5;\n    padding: 20px;\n    border-radius: 8px;\n    margin-bottom: 20px;\n}\n\n.productTitle,\n.originalTitle {\n    color: #333;\n    margin: 0;\n    padding: 0;\n    font-weight: bold;\n}\n\n.productDetails,\n.trainingDetails {\n    margin: 10px 0;\n}\n\n.productDetails p,\n.trainingDetails p,\n.trainingDetails label {\n    margin: 5px 0;\n    color: #666;\n}\n\n.checkboxInput {\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"individualProductPage": `IndividualProductPage_individualProductPage__5oz7O`,
	"header": `IndividualProductPage_header__gYI5j`,
	"productInfo": `IndividualProductPage_productInfo__W6NZb`,
	"aiTraining": `IndividualProductPage_aiTraining__xfysO`,
	"productTitle": `IndividualProductPage_productTitle__JuOi1`,
	"originalTitle": `IndividualProductPage_originalTitle__ugO5J`,
	"productDetails": `IndividualProductPage_productDetails__peyjT`,
	"trainingDetails": `IndividualProductPage_trainingDetails__aPAJo`,
	"checkboxInput": `IndividualProductPage_checkboxInput__8EwZm`
};
export default ___CSS_LOADER_EXPORT___;
