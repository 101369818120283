import { handlePriceChange } from './handlePriceChange';
import { handleSave } from './handleSave';

export function PriceInputFIeld({ product, setData, setIsSaving, productgroup }) {
  // Function to handle saving when input loses focus
  const handleBlur = (e) => {
    handleSave(
      [
        {
          ProductName: product.ProductName,
          averagePrice: Number(e.target.value),
          manuallychecked: true,
          lastUpdated: new Date().toISOString(),
          productGroup: productgroup,
        },
      ],
      setIsSaving
    );
  };

  return (
    <input
      type="number"
      value={product.averagePrice}
      onChange={(e) => handlePriceChange(e, product.ProductName, setData)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {

          handleSave(
            [
              {
                ProductName: product.ProductName,
                averagePrice: Number(e.target.value),
                manuallychecked: true,
                lastUpdated: new Date().toISOString(),
                productGroup: productgroup,
              },
            ],
            setIsSaving
          );
        }
      }}
      onBlur={handleBlur} // Save on losing focus
    />
  );
}
