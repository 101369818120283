// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

/* Apply styles to even rows */
tr:nth-child(even) {
    background-color: #ffffff;
    /* Light grey */
}

/* Apply styles to odd rows */
tr:nth-child(odd) {
    background-color: #d4f0eb;
    /* White */
}`, "",{"version":3,"sources":["webpack://./src/views/salesPage/tableStyles.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf,8BAA8B;AAC9B;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA,6BAA6B;AAC7B;IACI,yBAAyB;IACzB,UAAU;AACd","sourcesContent":["/* styles.css */\n\n/* Apply styles to even rows */\ntr:nth-child(even) {\n    background-color: #ffffff;\n    /* Light grey */\n}\n\n/* Apply styles to odd rows */\ntr:nth-child(odd) {\n    background-color: #d4f0eb;\n    /* White */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
