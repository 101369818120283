import React, { useRef } from 'react';
import { saveChanges } from '../../controller/saveChanges';
import { processInput } from './socketIOOperations';
import { calculateAverageMarginAndProfit } from '../../helpers/calculateAverageMarginAndProfit';

export function TopOptions({ setSoldIsChecked, inputValues, handleInputChange, data, setData, purchaseData, soldIsChecked, totalProfitsRef, setPurchaseData }) {
      const inputRef = useRef(null);

      const handleAddProduct = () => {
            const adID = inputRef.current.value;
            // Create a new inputValues object with the adID
            const newInputValues = { ...inputValues, searchOnAdID: adID };
            processInput(newInputValues, data, setData, setPurchaseData);
      };

      const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                  handleAddProduct();
            }
      };

      return (
            <div>
                  <input type="text" name="searchOnAdID" placeholder="Enter Ad ID Here" ref={inputRef} onKeyDown={handleKeyDown} />
                  <button onClick={handleAddProduct}>Add product</button>
                  <button onClick={() => saveChanges(purchaseData)}>Save changes</button>
                  <label>
                        <input type="checkbox" checked={soldIsChecked} onChange={(e) => setSoldIsChecked(e.target.checked)} />
                        Show sold & Refunded
                  </label>
                  <div>
                        Average margin:
                        {calculateAverageMarginAndProfit(totalProfitsRef.current).averageMargin}, Average profit: €{calculateAverageMarginAndProfit(totalProfitsRef.current).averageProfit}
                  </div>
            </div>
      );
}
