import { handleEbayLinkClick } from './ebayClickHandler';
import { deleteHandler } from './deleteHandler';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { PriceInputFIeld } from '../shared/InputField';

export function ProductRow({ product, setIsSaving, setData, highlightProduct, totalSearches, productRefs, productGroup }) {
    // const isHighlighted = highlightProduct === product.ProductName;

      const searchPercentage = totalSearches ? (((product.searchedBy || 0) / totalSearches) * 100).toFixed(2) : 0;
      const ref = (el) => (productRefs.current[product.ProductName] = el);
 

      return (
            <tr
                  key={product.ProductName}
                  ref={ref}
            
            >
                  <td>
                        <Link to={`/individualProduct/${encodeURIComponent(product.ProductName)}`}>{product.ProductName}</Link>
                  </td>
                  <td>
                        <PriceInputFIeld product={product} setData={setData} setIsSaving={setIsSaving} productgroup={productGroup} />
                  </td>
                  <td>
                        <button onClick={() => handleEbayLinkClick(product.ProductName)}>Go to eBay</button>
                  </td>
                  <td>{product.lastUpdated ? new Date(product.lastUpdated).toLocaleString() : 'Not updated'}</td>
                  <td>{searchPercentage}%</td>
                  <td>
                        <button onClick={() => deleteHandler(product, setData)}>Delete</button>
                  </td>
            </tr>
      );
}
