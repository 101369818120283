import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { sha256 } from 'crypto-hash';
import Cookies from 'js-cookie';

const Login = ({ isAuthenticated, onLogin }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // This hash should match the one produced by hashing the password you expect to be entered
  const hardcodedHash = '27ce21bacb7ca8a87e5abc4af194f7b1d6e3a6752c31de520a91e7b3da91e14e';

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Hash the password entered by the user
    const hashedInput = await sha256(password);

    // Compare the hash of the entered password with the stored hash
    if (hashedInput === hardcodedHash) {
      Cookies.set('auth', 'true', { expires: 365 });
      onLogin();
    } else {
      setError('Incorrect password');
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <button type="submit">Log In</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
};

export default Login;