export const calculateProfit = (amount) => {
      const baseRate = 6.5; // 6.5%
      const additionalRate = 2; // 2%
      const threshold = 400; // Threshold for applying different rates
      let fee = 0;

      if (amount <= threshold) {
            // If the amount is €400 or less, apply the base rate
            fee = amount * (baseRate / 100);
      } else {
            // If the amount is more than €400, calculate separately
            const baseFee = threshold * (baseRate / 100); // Fee for the first €400
            const additionalFee = (amount - threshold) * (additionalRate / 100); // Fee for the amount over €400
            fee = baseFee + additionalFee;
      }

      return amount - fee; // Returns the amount after subtracting the fee
};
