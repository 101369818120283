import { useRef } from 'react';

export const PrePrinter = ({ item }) => {
      const iframeRef = useRef(null);

      const handlePrint = () => {
            const printContent = `${item.ProductName} 
            AdID: ${item.adId} 
            Ebay AdID: ${item.ebayAdId}`;
            const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;

            iframeDoc.open();
            iframeDoc.write(`
            <html>
            <head>
                <title>Print</title>
            </head>
            <body>
                <pre>${printContent}</pre>
            </body>
            </html>
        `);
            iframeDoc.close();

            iframeRef.current.onload = function () {
                  iframeRef.current.contentWindow.print();
            };
      };

      return (
            <div>
                  <iframe ref={iframeRef} style={{ display: 'none' }} />
                  <button
                        onClick={handlePrint}
                        className="btn btn-primary me-2" // Bootstrap class for margin-right
                        style={{
                              marginLeft: '10px',
                              backgroundColor: '#1AA99D',
                              borderColor: '#1AA99D',
                              width: '160px'
                        }}
                  >
                        Print boxlabel
                  </button>
            </div>
      );
};
