import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Login from './login/Login';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { SalesPage } from './views/salesPage/SalesPage';
import { ProductList } from './views/productPage/ProductPage';
import { MainPage } from './views/mainPage/MainPage';
import { Paypal } from './views/paypal/Paypal';
import { IndividualProductPage } from './views/individualProductPage/IndividualProductPage';
import { CustomerService } from './views/customerService/CustomerService';
import { ForwardPush } from './views/forwardPush/ForwardPush';
import ClipLoader from 'react-spinners/ClipLoader';

function App() {
      const [isAuthenticated, setIsAuthenticated] = useState(false);
      const [isLoaded, setIsLoaded] = useState(false);

      const handleLogin = () => {
            setIsAuthenticated(true);
      };

      useEffect(() => {
            const checkAuth = async () => {
                  const authCookie = Cookies.get('auth');
                  setIsLoaded(false); // Set loading to false initially
                  if (authCookie === 'true') {
                        setIsAuthenticated(true);
                  }
                  setIsLoaded(true); // Set loading to true after check
            };

            checkAuth();
      }, []);
      return (
            <Router basename="/sales">
                  {isLoaded ? (
                        <Routes>
                              <Route path="/login" element={!isAuthenticated ? <Login onLogin={handleLogin} /> : <Navigate replace to="/" />} />
                              {isLoaded && isAuthenticated ? (
                                    <>
                                          <Route path="/" element={<MainPage />} />
                                          <Route path="/priceList" element={<ProductList />} />
                                          <Route path="/salespage" element={<SalesPage />} />
                                          <Route path="/paypal" element={<Paypal />} />
                                          <Route path="/forwardPush/:text/:adId" element={<ForwardPush />} />
                                          <Route path="/afterPay/:adId" element={<ForwardPush />} />
                                          <Route path="/customerService" element={<CustomerService />} />
                                          <Route path="/product/:productGroup" element={<ProductList />} />
                                          <Route path="/individualProduct/:productNameOrID" element={<IndividualProductPage />} />
                                    </>
                              ) : (
                                    <Route path="*" element={<Navigate replace to="/login" />} />
                              )}
                        </Routes>
                  ) : (
                        <div
                              style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100vh'
                              }}
                        >
                              <ClipLoader color="#36d7b7" size={150} />
                        </div>
                  )}
            </Router>
      );
}

export default App;
