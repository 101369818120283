export function calculateAverageMarginAndProfit(data) {
      // Filter entries with both pricePayed and subtotalValue
      const filteredData = data.filter((entry) => entry.pricePayed !== undefined && entry.subtotalValue !== undefined);

      // Calculate total profit and total margin
      let totalProfit = 0;
      let totalMargin = 0;

      filteredData.forEach((entry) => {
            const profit = entry.subtotalValue - parseFloat(entry.pricePayed);
            const margin = (profit / entry.subtotalValue) * 100;
            totalProfit += profit;
            totalMargin += margin;
      });

      // Calculate average profit and average margin
      const averageProfit = totalProfit / filteredData.length;
      const averageMargin = totalMargin / filteredData.length;

      return {
            averageProfit: averageProfit.toFixed(2), // Formatting to two decimal places
            averageMargin: averageMargin.toFixed(2) + '%' // Adding '%' for margin
      };
}
