export const handleEbayLinkClick = (productName) => {
      const windowFeatures = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes,width=600,height=600';
      window.open(
            `https://www.ebay.de/sch/i.html?_nkw=${encodeURIComponent(
                  productName
            )}&_in_kw=3&_sacat=0&LH_Complete=1&LH_Sold=1&LH_BIN=1&LH_ItemCondition=3000&_fslt=1&LH_SellerType=1&rt=nc&LH_PrefLoc=1`,
            '_blank',
            windowFeatures
      );
};
