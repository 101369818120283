export const mergeData = (newData, setData, setTotalSearches) => {
 
  setData((currentData) => {
    const dataMap = new Map(currentData.map((item) => [item.ProductName, item]))
console.log(newData);
    newData.data.forEach((newItem) => {
      const existingItem = dataMap.get(newItem.ProductName)
      if (existingItem) {
        const updatedItem = {
          ...newItem,
          averagePrice: existingItem.averagePrice,
          lastUpdated: existingItem.manuallychecked
            ? existingItem.lastUpdated
            : newItem.lastUpdated,
          manuallychecked: existingItem.manuallychecked || false,
          searchedBy: existingItem?.searchedBy?.length || 0,
        }
        dataMap.set(newItem.ProductName, updatedItem)
      } else {
        dataMap.set(newItem.ProductName, newItem)
      }
    })

    const updatedData = Array.from(dataMap.values())
   
    const total = updatedData.reduce(
      (acc, item) => acc + (item.searchedBy || 0),
      0
    )
    setTotalSearches(total)

    return updatedData.sort((a, b) => {
      if (!a.lastUpdated && b.lastUpdated) return -1
      if (!b.lastUpdated && a.lastUpdated) return 1
      if (!a.lastUpdated && !b.lastUpdated) {
        return (b.searchedBy || 0) - (a.searchedBy || 0)
      }

      const dateDiff = new Date(a.lastUpdated) - new Date(b.lastUpdated)
      if (dateDiff === 0) {
        return (b.searchedBy || 0) - (a.searchedBy || 0)
      }
      return dateDiff
    })
  })
}
