import { io } from 'socket.io-client';

export const handleSave = (data, setIsSaving) => {
      setIsSaving(true);
      const jsonData = JSON.stringify(data);
      const socket = io(process.env.REACT_APP_ENDPOINT);

      socket.emit('save-data', jsonData, (response) => {

            socket.disconnect();
            setIsSaving(false);
      });
};
