import { saveChanges } from '../../controller/saveChanges';

export const handleStatusChange = async (value, index, purchaseData, setPurchaseData) => {
      const currentDate = new Date();

      // Format the date in German style
      const formattedDate = currentDate.toLocaleString('de-DE');

      if (!purchaseData[index].statusChanges) {
            purchaseData[index].statusChanges = [];
      }

      purchaseData[index].status = value;

      // Now it's safe to push the new status change
      purchaseData[index].statusChanges.push({
            status: value,
            date: formattedDate
      });
      saveChanges([purchaseData[index]], setPurchaseData, true);
};

export const handleStatusChangeNew = async (value, item, setPurchaseData) => {
      const currentDate = new Date();

      // Format the date in German style
      const formattedDate = currentDate.toLocaleString('de-DE');

      if (!item.statusChanges) {
            item.statusChanges = [];
      }

      item.status = value;

      // Now it's safe to push the new status change
      item.statusChanges.push({
            status: value,
            date: formattedDate
      });
      saveChanges([item], setPurchaseData, true);
};
