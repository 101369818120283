import React from 'react';
import { handleSave } from '../shared/handleSave';
import { useNavigate } from 'react-router-dom';

export function TopOptions({ data, setIsSaving, newProductName, setNewProductName, newProductPrice, setNewProductPrice, handleAddNewProduct, isSaving }) {
      const navigate = useNavigate();
      return (
            <div className="sticky-button">
                  <button onClick={() => handleSave(data, setIsSaving)} disabled={isSaving}>
                        {isSaving ? 'Saving...' : 'Save'}
                  </button>
                  there are at the moment: {data.length} entries
                  <div className="purchased-button">
                        <button onClick={() => navigate('/salespage')}>Purchased</button>
                  </div>
                  <div>
                        <input type="text" placeholder="Product Name" value={newProductName} onChange={(e) => setNewProductName(e.target.value)} />
                        <input type="number" placeholder="Average Price" value={newProductPrice} onChange={(e) => setNewProductPrice(e.target.value)} />
                        <button onClick={handleAddNewProduct}>Add New Product</button>
                  </div>
            </div>
      );
}
