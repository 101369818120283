import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  receivePriceCollections, requestData
  
} from '../../controller/socketIOOperations'
import './mainPage.css';


export function MainPage() {
  const [priceCollections, setPriceCollections] = useState([])


    const linkStyle = {
    color: '#333333',           // Sets the text color to dark grey
    textDecoration: 'none'       // Removes the underline from the text
  };

  useEffect(() => {
    requestData([
    'priceCollections'
])
    const fetchPriceCollections = async () => {
      try {
        const data = await receivePriceCollections()
        setPriceCollections(data)
      } catch (error) {
        console.error('Failed to fetch price collections', error)
      }
    }

    fetchPriceCollections()
  }, [])

return (
    <div className="app-container">
      <header>
        <img src="/logo.png" alt="Your green phone" className="logo" />
      </header>
      

    <div className="navigation">
      <button className="btn btn-primary" style={{ backgroundColor: '#1AA99D', borderColor: '#1AA99D', width: "200px" }}>
        <Link to="/salespage" style={linkStyle}>Sales Page</Link>
      </button>
      <button className="btn btn-primary" style={{ backgroundColor: '#1AA99D', borderColor: '#1AA99D', width: "200px" }}>
        <Link to="/paypal" style={linkStyle}>PayPal</Link>
      </button>
      <button className="btn btn-primary" style={{ backgroundColor: '#1AA99D', borderColor: '#1AA99D', width: "200px" }}>
        <Link to="/customerService" style={linkStyle}>AI Customer Service</Link>
      </button>
    </div>
 <div style={{ width: '100%', height: '3px', backgroundColor: '#0F8F7D',marginTop:20 }}></div>
    <p>Group prices</p>
{priceCollections.length > 0 ? (
        <ul className="price-list">
          {priceCollections.map((collection, index) => (
            <li key={index}>
              <button className="btn btn-primary" style={{ backgroundColor: '#1AA99D', borderColor: '#1AA99D',width: "200px" }}>
            <Link to={`/product/${collection}`} style={{ color: 'black', textDecoration: 'none' }}>
              {collection}
            </Link>
          </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No price collections found.</p>
      )}


    </div>
  );
}
