import { io } from 'socket.io-client';

export const deleteHandler = (productToDelete, setData) => {
      const { ...productWithoutId } = productToDelete;

      const socket = io(process.env.REACT_APP_ENDPOINT);
      socket.emit('delete', JSON.stringify(productWithoutId), () => {
            socket.disconnect();
      });

      setData((currentData) => {
            const newData = currentData.filter((product) => product.ProductName !== productToDelete.ProductName);
            return newData;
      });
};
